import React from "react"
import { /* Link, */ graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Testimonial from "../components/testimonial"
import Carousel from "../components/carousel"
import { default as IconSend } from "../components/icons/send"

const IndexPage = ({
	data: {
		headshot: { childImageSharp },
		allMarkdownRemark: { edges },
	},
}) => {
	const Testimonials = edges
		.filter(edge => !!edge.node.frontmatter.author)
		.map(edge => <Testimonial key={edge.node.id} testimonial={edge.node} />)
	return (
		<Layout>
			<SEO keywords={[`portfolio`, `music`, `musician`, `opera`, `singer`, `soprano`, `theater`, `actress`]} />

			<section className="feature">
				<div className="container max-width-adaptive-lg margin-y-lg">
					<div className="feature__grid grid gap-md items-center">
						<div className="col-6@md">
							<div className="feature__item">
								<div className="text-component text-lg">
									<h1 className="text-xl">Lydia Rose Eiche is a soprano, opera singer, and actress based in Milwaukee.</h1>
								</div>
							</div>
						</div>
						<div className="col-6@md">
							<div className="feature__item feature__item--media">
								<figure className="blob-radius">
									<Img fluid={childImageSharp.fluid} />
								</figure>
							</div>
						</div>
					</div>
				</div>
			</section>

			<div className="container max-width-xxxxs text-component">
				<div className="text-divider"></div>
			</div>

			<section>
				<div className="container max-width-sm margin-y-lg">
					<div className="text-component text-lg">
						<h2 id="bio" className="text-lg"><span role="img" aria-hidden="true">👋</span> Meet Lydia</h2>
					</div>
				</div>
				<div className="container max-width-sm margin-y-lg">
					<div className="text-component text-md">
						<p>
							Equally at home in opera, musical theatre, and on the concert stage, Lydia
							Rose Eiche has been praised for her “great warmth, intelligence and power.”
						</p>
						<p>
							She’s sung roles as diverse as Pamina in <em>Zie Magic Flute</em>, Yitzhak in <em>Hedwig
							and the Angry Inch</em>, Mama Euralie in <em>Once on This Island</em>, the bride
							Milica in the Serbian opera <em>Svadba–Wedding</em>, Babette in <em>Beauty and the
							Beast</em>, and the title character in the comic one-act <em>Captain Lovelock</em>.
						</p>
						<p>
							Lydia has performed with the Milwaukee Symphony Orchestra; Skylight Music
							Theatre; Milwaukee Opera Theatre; Third Avenue Playhouse; All In
							Productions; First Stage Children’s Theatre; Sunset Playhouse; Waukesha
							Civic Theatre; Soulstice Theatre; Haylofters; Boulevard Theatre; Capital
							City Theatre; Forte Theatre Company; Plymouth Chorale; Fresco Opera
							Theatre; Opera Orvieto, in Italy; and Miami Music Festival.
						</p>
						<p>
							Lydia studies with Marlee Sabo.
						</p>
						{/* <p>
							<span role="img" aria-hidden="true">👉</span> <Link to="/bio/">Read more</Link>
						</p> */}
					</div>
				</div>
			</section>

			<div className="container max-width-xxxxs text-component">
				<div className="text-divider"></div>
			</div>

			<section>
				<div className="container max-width-sm margin-top-lg margin-bottom-md">
					<div className="text-component text-lg">
						<h2 id="press-quotes" className="text-lg">Press Quotes</h2>
					</div>
				</div>
				<Carousel>
					{Testimonials}
				</Carousel>
			</section>

			<div className="container max-width-xxxxs text-component">
				<div className="text-divider"></div>
			</div>

			<section>
				<div className="container max-width-adaptive-sm margin-y-lg">
					<h1 id="contact" className="text-xl margin-bottom-md"><span role="img" aria-hidden="true">💌</span> Get In Touch</h1>
					<form name="contact" method="post" action="/success" data-netlify="true" data-netlify-honeypot="bot-field">
						<input type="hidden" name="subject" value="LRE Contact Form Submission" />
						<input type="hidden" name="bot-field" />
						<div className="grid gap-sm">
							<div className="col-12 col-6@md">
								<label htmlFor="name" className="form-label color-contrast-medium">Name</label>
								<input type="text" id="name" name="name" className="form-control width-100%" required />
							</div>
							<div className="col-12 col-6@md">
								<label htmlFor="email" className="form-label color-contrast-medium">Email</label>
								<input type="email" id="email" name="email" className="form-control width-100%" required />
							</div>
							<div className="col-12">
								<label htmlFor="message" className="form-label color-contrast-medium">Message</label>
								<textarea type="text" id="message" name="message" className="form-control width-100%" required></textarea>
							</div>
							<div className="col-12 col-6@md">
								<button type="submit" className="btn btn--primary">
									<IconSend className="margin-right-xxxs" />
									<span>Send!</span>
								</button>
							</div>
						</div>
					</form>

				</div>
			</section>

		</Layout>
	)
}

export default IndexPage

export const indexQuery = graphql`
  query {
		headshot: file(relativePath: { eq: "headshot.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
    allMarkdownRemark(sort: { order: ASC, fields: [id] }) {
      edges {
        node {
					id
					html
					frontmatter {
						author
						source
					}
        }
      }
    }
  }
`
