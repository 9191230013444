import React from 'react';

function RightArrow(props) {
	return (
		<svg className={'icon icon--arrow-right' + ( props.className ? ` ${props.className}` : '' )} aria-hidden={!props.title} height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
			{!!props.title &&
				<title>{props.title}</title>
			}
			<g fill="currentColor">
				<path d="M7,23.414L5.586,22l10-10l-10-10L7,0.586l10.707,10.707c0.391,0.391,0.391,1.023,0,1.414L7,23.414z" fill="currentColor"/>
			</g>
		</svg>
	);
};

export default RightArrow;