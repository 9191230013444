import React from "react"
import showdown from "showdown"

import { default as IconQuote } from "../components/icons/quote"

const converter = new showdown.Converter()

const Testimonial = ({ testimonial }) => (
	<div className="testimonial">
		<IconQuote className="testimonial__icon" />
		<blockquote className="testimonial__quote" dangerouslySetInnerHTML={{__html: testimonial.html}} />
		<footer className="testimonial__footer">
			<cite className="testimonial__cite text-component width-100%">
				<strong dangerouslySetInnerHTML={{__html: converter.makeHtml(testimonial.frontmatter.author)}} />
				<span dangerouslySetInnerHTML={{__html: converter.makeHtml(testimonial.frontmatter.source)}} />
			</cite>
		</footer>
	</div>
)

export default Testimonial
