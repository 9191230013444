import React, { Component  } from "react"
import Swiper from "react-id-swiper"

import { default as IconLeftArrow } from "./icons/arrow-left"
import { default as IconRightArrow } from "./icons/arrow-right"

export default class Carousel extends Component {
	render() {
		const calcHeight = () => {
			let height = document.querySelector('.swiper-slide-active .testimonial').clientHeight;
			document.querySelector('.swiper-container').style.height = `${height}px`;
		}

		const settings = {
			slidesPerView: 1,
			centeredSlides: true,
			slideToClickedSlide: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			renderPrevButton: () => <div className="swiper-button-prev flex items-center display@sm"><div className="btn btn--subtle btn--icon radius-50% margin-x-xs"><IconLeftArrow /></div></div>,
			renderNextButton: () => <div className="swiper-button-next flex items-center display@sm"><div className="btn btn--subtle btn--icon radius-50% margin-x-xs"><IconRightArrow /></div></div>,
			autoplay: {
				delay: 8000,
				disableOnInteraction: false,
			},
			loop: true,
			breakpoints: {
				768: {
					slidesPerView: 2,
				},
			},
			on: {
				'init': calcHeight,
				'slideChangeTransitionStart': calcHeight
			},
		}

		return (
			<>
				<Swiper {...settings}>
					{React.Children.map(this.props.children, (child, i) => {
						return <div>{child}</div>
					})}
				</Swiper>
			</>
		);
	}
}
